import { useCallback, useMemo, useEffect } from 'react';
import {
  Flex, Text, Box, Button, Divider, Group, Tooltip,
  Center,
  ScrollArea,
  type BoxComponentProps
} from '@mantine/core';
import { useParams } from 'react-router-dom';

import ModalContent from '../../ModalContent';
import type {
  RequirementResponse, SourceItem, OrganizationResource, WithoutPermissions, MemoryItem
} from '../../../types/apiTypes';
import GeneratedResponseCard from '../../GeneratedResponseCard';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import { HelpCircle } from '../../../icons';
import CenteredLoader from '../../CenteredLoader';
import ResourceHeader from './ResourceHeader';

const FACTS_TOOLTIP_TEXT = 'Fact management will store information to apply for future updates to this proposal or ' +
  'all future proposals. You can access these facts under \'Proposal Management\'. You can access \'global\' facts ' +
  'from User Settings.';
const PROPOSAL_CONTEXT_TOOLTIP_TEXT = 'Win Themes / Differentiators are proposal-specific strategy details ' +
  'you provide. You can access them anytime under \'Proposal Management.\'';

interface ResourceInfo {
  resource: WithoutPermissions<OrganizationResource>
  sources: SourceItem[]
}

interface GroupedSources {
  memoryItems: Array<WithoutPermissions<MemoryItem>>
  organizationResources: Record<string, ResourceInfo>
  proposalContextSources: string[]
}

const sortByPageComparisonFn = (sourceA: SourceItem, sourceB: SourceItem): number => (
  (sourceA.page ?? 0) - (sourceB.page ?? 0)
);

const groupSources = (sources: SourceItem[]): GroupedSources => {
  const orgResourceSources: Record<string, ResourceInfo> = {};
  const memoryItemSources: Array<WithoutPermissions<MemoryItem>> = [];
  const proposalContextSources: string[] = [];
  sources.forEach((source) => {
    if (
      source.type === 'organization_resource' &&
      source.organization_resource !== undefined &&
      source.organization_resource != null
    ) {
      const resourceUid = source.organization_resource?.uid ?? '';
      if (resourceUid in orgResourceSources) {
        orgResourceSources[resourceUid].sources.push(source);
      } else {
        orgResourceSources[resourceUid] = {
          sources: [source],
          resource: source.organization_resource
        };
        orgResourceSources[resourceUid].sources = [source];
      }
    } else if (
      source.type === 'memory_item' &&
      source.memory_item !== undefined &&
      source.memory_item != null
    ) {
      memoryItemSources.push(source.memory_item);
    } else if (source.type === 'proposal_context') {
      proposalContextSources.push(source.text);
    }
  });
  return {
    memoryItems: memoryItemSources,
    organizationResources: orgResourceSources,
    proposalContextSources
  };
};

type CategoryHeaderProps = {
  headerText: string
  tooltipText: string
} & BoxComponentProps;

const CategoryHeader: React.FC<CategoryHeaderProps> = (
  { headerText, tooltipText, ...boxProps }: CategoryHeaderProps
) => (
  <Box {...boxProps}>
    <Group gap='xs'>
      <Text fw='bold' size='sm' style={{ margin: '0 0 0 0' }}>{headerText}</Text>
      <Tooltip
        label={tooltipText}
        events={{ hover: true, focus: false, touch: false }}
        w={590}
        multiline
      >
        <Center style={{ cursor: 'pointer' }}>
          <HelpCircle />
        </Center>
      </Tooltip>
    </Group>
    <Divider mt={8} size='sm' color='var(--mantine-color-gray-2)' />
  </Box>
);

interface Props {
  onClose: () => void
  requirementResponse: RequirementResponse
}

const ResponseSources: React.FC<Props> = (props: Props) => {
  const { onClose, requirementResponse } = props;
  const { source_analysis: sourceAnalysis } = requirementResponse;

  const { proposalUid, sectionUid } = useParams();

  const runFactCheckMutation = useEnsisMutation(
    `/app/requirement-responses/${requirementResponse.uid}/source-analysis`,
    {
      requestType: 'post',
      showSuccessMessage: false,
      queryKeysToInvalidate: [
        `/app/proposals/${proposalUid}/sections/${sectionUid}/requirement-responses`
      ]
    }
  );

  const handleRerunFactCheck = useCallback(() => {
    runFactCheckMutation.mutate({});
  }, [runFactCheckMutation.mutate]);

  useEffect(() => {
    const sourceAnalysisIsNull = sourceAnalysis === undefined || sourceAnalysis == null;
    if (!runFactCheckMutation.isPending && sourceAnalysisIsNull) {
      handleRerunFactCheck();
    }
  }, []);

  const groupedSources = useMemo(
    () => groupSources(sourceAnalysis?.source_items ?? []),
    [sourceAnalysis?.source_items]
  );

  const lastFactCheckTime = new Date(sourceAnalysis?.created_at ?? '');
  const lastResponseUpdateTime = new Date(requirementResponse?.updated_at ?? '');
  // Build in a little bit of buffer (1s) in case updates happen simultaneously
  const sourceAnalysisIsOutdated = (lastResponseUpdateTime.getTime() - lastFactCheckTime.getTime()) > 1000;

  let lastFactCheckTimeString = lastFactCheckTime?.toLocaleTimeString();
  if (lastFactCheckTime?.toLocaleDateString() !== new Date().toLocaleDateString()) {
    lastFactCheckTimeString = lastFactCheckTime?.toLocaleDateString();
  }

  const outdatedFactCheckBanner = (
    <Group p='8 16 8 16' mb={16} bg='var(--mantine-color-red-1)' justify='space-between'>
      <Text fz='sm'>Response has been modified. Would you like to rescan?</Text>
      <Button
        variant='subtle'
        color='red'
        onClick={handleRerunFactCheck}
      >
        Rescan
      </Button>
    </Group>
  );

  const loader = (
    <CenteredLoader flex={1} />
  );

  const documentSourcesSection = (
    <>
      {
        Object.values(groupedSources.organizationResources).map((resourceInfo) => (
          <Box key={resourceInfo.resource.uid} mb={16}>
            <ResourceHeader orgResource={resourceInfo.resource} />
            {
              resourceInfo.sources.toSorted(sortByPageComparisonFn).map((source) => {
                const hasPage = source.page !== undefined && source.page != null;
                const hasRow = source.row !== undefined && source.row != null;
                return (
                  <Box key={source.text} mb={16}>
                    <Text size='sm'>{`"${source.text}"`}</Text>
                    { hasPage && <Text fw={600} c='var(--mantine-color-gray-5)'>{`pg ${source.page}`}</Text> }
                    { hasRow && <Text fw={600} c='var(--mantine-color-gray-5)'>{`row ${source.row}`}</Text> }
                  </Box>
                );
              })
            }
          </Box>
        ))
      }
    </>
  );

  const factSourcesSection = (
    <>
      <CategoryHeader
        mb={16}
        tooltipText={FACTS_TOOLTIP_TEXT}
        headerText='Facts'
      />
      {
        groupedSources.memoryItems.map((memoryItem) => (
          <Box key={memoryItem.text} mb={16}>
            <Text size='sm'>{`- ${memoryItem.text}`}</Text>
          </Box>
        ))
      }
    </>
  );

  const proposalContextSourcesSection = (
    <>
      <CategoryHeader
        mb={16}
        tooltipText={PROPOSAL_CONTEXT_TOOLTIP_TEXT}
        headerText='Win Themes / Differentiators'
      />
      {
        groupedSources.proposalContextSources.map((proposalContextSource) => (
          <Box key={proposalContextSource} mb={16}>
            <Text size='sm'>{`- ${proposalContextSource}`}</Text>
          </Box>
        ))
      }
    </>
  );

  const hasFactSources = groupedSources.memoryItems.length > 0;
  const hasDocumentSources = Object.keys(groupedSources.organizationResources).length > 0;
  const hasProposalContextSources = groupedSources.proposalContextSources.length > 0;
  const hasSources = hasFactSources || hasDocumentSources || hasProposalContextSources;

  const sourcesEmptyState = (
    <Center pt={32}>
      <Text>No sources found</Text>
    </Center>
  );

  const sourcesSection = (
    <ScrollArea.Autosize flex={1} type='auto'>
      <Box pr={16} pl={16}>
        {
          sourceAnalysisIsOutdated && outdatedFactCheckBanner
        }
        <Text fw={500} fz='lg'>Sources List</Text>
        <Text fz='sm' mb={16} c='var(--mantine-color-gray-6)'>{`Last checked: ${lastFactCheckTimeString}`}</Text>
        { !hasSources && sourcesEmptyState }
        { hasDocumentSources && documentSourcesSection }
        { hasFactSources && factSourcesSection }
        { hasProposalContextSources && proposalContextSourcesSection }
      </Box>
    </ScrollArea.Autosize>
  );

  return (
    <ModalContent
      onClose={onClose}
      title='Source Verification'
      primaryButton={{
        label: 'Done',
        onClick: onClose
      }}
    >
      <Flex direction='row' align='stretch' mah='60vh'>
        <ScrollArea.Autosize flex={1} style={{ display: 'flex' }}>
          <GeneratedResponseCard
            requirementResponse={requirementResponse}
          />
        </ScrollArea.Autosize>
        { runFactCheckMutation.isPending ? loader : sourcesSection }
      </Flex>
    </ModalContent>
  );
};

export default ResponseSources;
