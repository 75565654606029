import { useCallback, useEffect, useState } from 'react';
import { Box, Text, Modal, Group, Center, Tooltip, LoadingOverlay, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { useParams } from 'react-router-dom';

import type {
  Proposal, RequirementResponse, ProposalSection, WithoutPermissions, RouteWithGet
} from '../../../types/apiTypes';
import { editorContentIsEmpty, getRequirementText } from '../../../utils/stringUtils';
import { defaultModalProps } from '../../../utils/mantineUtils';
import { EditRequirement } from '../../../components/Modals';
import { RequirementDropdown } from '../../../components/DropdownMenus';
import { type NarrativeOverlayState } from './EditorPane';
import OutlineResponseEditor from '../ResponseEditors/OutlineResponseEditor';
import { EditorPaneViewSelector, type ViewSelectorProps } from '../../../components';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import {
  type RequirementType,
  getRequirementIdentifierSpan,
  DEFAULT_EMPTY_REQUIREMENT
} from '../../../utils/requirementUtils';
import { GenerateAll as GenerateAllIcon } from '../../../icons';
import { withAnalyticsEvent } from '../../../utils/analyticsUtils';
import { OutlineOutputFormatType } from '../../../utils/constants';

type Props = {
  section: WithoutPermissions<ProposalSection>
  proposal: Proposal
  requirementResponses: RequirementResponse[]
  canEdit: boolean
  narrativeOverlayState?: NarrativeOverlayState
  complianceCheckIsRunning: boolean
  focusedRequirementUid: string
  onSelectRequirement?: (reqUid: string) => void
} & ViewSelectorProps;

/* eslint-disable-next-line max-len */
const EMPTY_STATE_COPY = 'This section has no requirements. Add requirements if needed, or generate narrative to draft content using AI.';
const ALERT_COPY = 'Import relevant past work and information about your organization into your Resources, ' +
  'and try again.';

const OutlineEditor: React.FC<Props> = (props: Props) => {
  const {
    section,
    proposal,
    requirementResponses,
    canEdit,
    focusedRequirementUid,
    onSelectRequirement
  } = props;

  const { proposalUid, sectionUid } = useParams();
  const [failedResponseUids, setFailedResponseUids] = useState<string[]>([]);
  const [currentRequirement, setCurrentRequirement] = useState<RequirementType>(DEFAULT_EMPTY_REQUIREMENT);
  const [editRequirementOpened, editRequirementHandlers] = useDisclosure();

  const sectionIsEmpty = (requirementResponses?.length ?? 0) === 0;
  const [shouldShowWarning, setShouldShowWarning] = useState(section.content !== null);

  const outlineOutputFormat = proposal.outline_output_format ?? OutlineOutputFormatType.BULLET_POINTS;
  /* eslint-disable-next-line max-len */
  const requirementResponseQueryRoute: RouteWithGet = `/app/proposals/${proposalUid}/sections/${sectionUid}/requirement-responses`;
  const editRequirementComplianceMutation = useEnsisMutation('/app/requirement-responses', {
    requestType: 'patch',
    queryKeysToInvalidate: [requirementResponseQueryRoute],
    showSuccessMessage: false
  });

  const generateRequirementResponses = useEnsisMutation(
    '/app/proposal-editing/generate-responses',
    {
      contentType: 'application/json',
      requestType: 'post',
      showSuccessMessage: false,
      queryKeysToInvalidate: [requirementResponseQueryRoute],
      onSuccess: (data) => {
        const failedUids = data.responses.filter((response) => (
          response.content === ''
        )).map((response) => response.uid);
        setFailedResponseUids(failedUids);
      }
    }
  );

  useEffect(() => {
    setShouldShowWarning(section.content !== null);
  }, [section]);

  const getRequirementComponent = useCallback((response: RequirementResponse) => {
    const requirementText = response.requirement?.text ?? '';
    const requirementUid = response.requirement?.uid ?? '';
    const requirementIdentifier = response.requirement?.identifier ?? '';
    const isSelected = requirementUid !== '' && requirementUid === focusedRequirementUid;
    const canClickRequirement = onSelectRequirement !== undefined;
    const onClickRequirement = () => {
      if (canClickRequirement) {
        onSelectRequirement(requirementUid);
      }
    };
    return (
      <Group
        justify='space-between'
        p='6.5px 16px'
        style={{
          backgroundColor: isSelected ? 'var(--mantine-color-green-1)' : undefined
        }}
      >
        <Text
          maw='calc(100% - 50px)'
          onClick={onClickRequirement}
          style={{ cursor: canClickRequirement ? 'pointer' : undefined }}
        >
          {getRequirementIdentifierSpan(requirementIdentifier)}
          {getRequirementText(requirementText, sectionIsEmpty)}
        </Text>
        {canEdit && requirementText !== '' && (
          <RequirementDropdown
            sectionData={proposal.sections?.map((section) => {
              return {
                title: section?.title,
                uid: section?.uid,
                childSections: section?.child_sections,
                parentSectionUid: section?.parent_proposal_section_uid ?? undefined
              };
            }) ?? [{ title: '', uid: '' }]}
            handleOpenEditRequirement={() => {
              setCurrentRequirement({
                requirementText,
                requirementUid,
                requirementResponseUid: response.uid,
                requirementIdentifier,
                sectionUid: response.proposal_section?.uid ?? '',
                ordinal: response.ordinal ?? 1,
                updatedAt: response.updated_at ?? ''
              });
              editRequirementHandlers.open();
            }}
            requirementResponseOrdinal={response.ordinal ?? 1}
            requirementResponseUid={response.uid}
            sectionUid={sectionUid ?? ''}
            requirementRemovalType='ARCHIVE'
            requirementUid={requirementUid}
            opportunityUid={proposal.opportunity?.uid ?? ''}
          />
        )}
      </Group>);
  }, [
    section,
    editRequirementHandlers,
    proposal,
    sectionIsEmpty,
    canEdit,
    focusedRequirementUid,
    onSelectRequirement
  ]);

  const onEditRequirement = useCallback((requirementResponseUid: string) => {
    // mutatation clears compliance for edited requirement
    editRequirementComplianceMutation.mutate({
      proposal_uid: proposalUid ?? '',
      edits: [{
        requirement_response_uid: requirementResponseUid,
        section_text_references: [],
        is_compliant: false
      }]
    });
  }, [editRequirementComplianceMutation, proposalUid]);

  const outlineEmptyState = (
    <Center
      mt={8}
      style={{
        borderRadius: 4
      }}
      h={250}
      bg='var(--mantine-color-greyPurple-0)'
      ta='center'
    >
      <Text p={8}>
        {EMPTY_STATE_COPY}
      </Text>
    </Center>
  );

  const emptyResponses = requirementResponses.filter((response) => (
    editorContentIsEmpty(response.content)
  ));
  const hasEmptyResponses = emptyResponses.length > 0;

  const onClickGenerateAllResponses = useCallback(() => {
    generateRequirementResponses.mutate({
      requirement_response_uids: emptyResponses.map((response) => response.uid)
    });
  }, [generateRequirementResponses, emptyResponses]);

  const onClickGenerateSingleResponse = useCallback((requirementResponse: RequirementResponse) => {
    generateRequirementResponses.mutate({
      requirement_response_uids: [requirementResponse.uid]
    });
  }, [generateRequirementResponses]);

  const resetFailedUids = useCallback(() => { setFailedResponseUids([]); }, []);

  const generateAllButton = (
    <Tooltip
      events={{
        hover: true,
        focus: false,
        touch: false
      }}
      label={hasEmptyResponses ? 'Generates all missing responses' : 'No responses to generate'}
    >
      <Button
        variant='subtle'
        onClick={withAnalyticsEvent(onClickGenerateAllResponses, 'GenerateAllResponses')}
        disabled={!hasEmptyResponses}
      >
        <Group gap='xs'>
          <GenerateAllIcon width={20} height={20} />
          <Text fw='bold' c='var(--mantine-color-lightPurple-5)'>Generate All</Text>
        </Group>
      </Button>
    </Tooltip>
  );

  return (
    <Box>
      <LoadingOverlay visible={generateRequirementResponses.isPending} />
      <EditorPaneViewSelector
        {...props}
        rightSection={generateAllButton}
      />
      <Box
        p='0px 8px'
        h={'calc(100vh - 210px)'}
        style={{ overflow: 'auto' }}
      >
        <Modal opened={editRequirementOpened} {...defaultModalProps}>
          <EditRequirement
            close={editRequirementHandlers.close}
            requirement={currentRequirement}
            onEditRequirement={() => { onEditRequirement(currentRequirement.requirementResponseUid); }}
          />
        </Modal>
        {sectionIsEmpty
          ? outlineEmptyState
          : requirementResponses?.map((response) => {
            return (
              <Box
                key={response.uid}
                mt={8}
                style={{ border: '1px solid var(--mantine-color-gray-2)', borderRadius: '4px' }}
              >
                {getRequirementComponent(response)}
                <OutlineResponseEditor
                  canEdit={canEdit}
                  responseData={response}
                  shouldShowWarning={shouldShowWarning}
                  setShouldShowWarningFalse={() => { setShouldShowWarning(false); }}
                  outlineOutputFormat={outlineOutputFormat}
                  onGenerateText={() => { onClickGenerateSingleResponse(response); }}
                  alertText={failedResponseUids.includes(response.uid) ? ALERT_COPY : undefined}
                  onEditorUpdate={resetFailedUids}
                />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default OutlineEditor;
